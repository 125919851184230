import { loadYouTubeApi } from '../../utils/loadYouTubeApi';
import { setFocusTrap } from '../../utils/focusTrap';

const CLASSES = {
  youtubeIframe: 'cmp-embed__youtube-iframe',
  youtubeEmbed: '.embed.video-youtube',
  thumbnailEmbed: '.cmp-embed--thumbnail',
  thumbnailButton: 'cmp-embed__load-button',
  closeButton: 'cmp-embed__close-button',
  fullscreen: 'fullscreen',
  scrollLock: 'scroll-lock',
  hidden: 'hidden',
};

const checkForYouTubeEmbed = () => { 
  return document.querySelector(CLASSES.youtubeEmbed) ? true : false;
};

class YouTubeEmbed {

  constructor(embedComponent) {
    this.embedComponent = embedComponent;
    this.thumbnailWrapper = this.embedComponent.querySelector(CLASSES.thumbnailEmbed);
    this.closeButton = this.embedComponent.querySelector(`.${CLASSES.closeButton}`);
    this.playerData = JSON.parse(this.thumbnailWrapper.dataset.iframe);
    this.allowFullscreenModal = this.playerData.allowFullscreenModal === 'true';
    this.loadButton;
    this.playerIframe;
    this.player;
    this.loadedVideo = false;
    this.isFullscreen = false;
    this.focusTrap;

    this.init();
  }

  addPlayButton() {
    this.loadButton = document.createElement('button');
    this.loadButton.className = CLASSES.thumbnailButton;
    this.loadButton.innerHTML = '<span></span>';
    this.loadButton.setAttribute('aria-label', this.playerData.loadButtonAriaLabel);
    this.thumbnailWrapper.append(this.loadButton);
  }

  addCloseButton() {
    this.closeButton = document.createElement('button');
    this.closeButton.className = CLASSES.closeButton;
    this.thumbnailWrapper.append(this.closeButton);
  }

  hideThumbnail() {
    this.thumbnailWrapper.querySelector('picture')?.classList.add(CLASSES.hidden);
    this.loadButton.classList.add(CLASSES.hidden);
  }

  getPlayerId() {
    const path = new URL(this.playerData.src).pathname.split('/');
    return `youtube-player-${path.at(-1)}`;
  }

  enableAutoplay(src) {
    const url = new URL(src);
    const queryParams = url.searchParams;
    queryParams.set('mute', '1');
    queryParams.set('autoplay', '1');
    queryParams.set('playsinline', '1');
    queryParams.set('rel', '0');
    return url.toString();
  }

  enterFullScreen() {
    if (!this.allowFullscreenModal) return;
    document.body.classList.add(CLASSES.scrollLock);
    this.thumbnailWrapper.classList.add(CLASSES.fullscreen);
    this.isFullscreen = true;
    this.focusTrap = setFocusTrap(this.embedComponent);
  }

  closeFullscreen() {
    this.thumbnailWrapper.classList.remove(CLASSES.fullscreen);
    document.body.classList.remove(CLASSES.scrollLock);
    this.player.pauseVideo();
    this.isFullscreen = false;
    this.focusTrap.destroy();
  }

  addEvents() {
    this.loadButton.addEventListener('click', () => {
      if (!this.videoLoaded) {
        const videoContainer = document.querySelector('.cmp-embed__youtube');
        if (videoContainer) {
          videoContainer.classList.add('hide-play-icon');
        }
          this.loadVideo();
          this.videoLoaded = true;
      } else {
          this.enterFullScreen();
      }
    });
    this.closeButton?.addEventListener('click', this.closeFullscreen.bind(this));
  }

  loadVideo() {
    this.hideThumbnail();

    this.playerIframe = document.createElement('iframe');
    this.playerIframe.classList.add(CLASSES.youtubeIframe);
    this.playerIframe.title = this.playerData.playerTitle;
    this.playerIframe.src = this.enableAutoplay(this.playerData.src);
    this.playerIframe.setAttribute('frameborder', '0');
    this.playerIframe.allow = this.playerData.allow;
    this.playerIframe.ariaLabel = this.playerData.playerAriaLabel;
    this.playerIframe.id = this.getPlayerId();

    this.thumbnailWrapper.appendChild(this.playerIframe);

    this.player = new YT.Player(this.playerIframe.id,{
      events: {
        onReady: (event) => {
          event.target.playVideo();
        },
        onStateChange: (event) => {
          if (
              !this.isFullscreen &&
              this.allowFullscreenModal &&
              event.data == YT.PlayerState.PLAYING
          ) {
              this.enterFullScreen();
          }
      },
      }
    });

    this.enterFullScreen();

  }

  init() {
    this.thumbnailWrapper.style.paddingBottom = this.playerData.iFrameAspectRatio;
    if (!this.closeButton) this.addCloseButton();
    this.addPlayButton();
    this.addEvents();
  }
}

const initYouTube = () => {
  loadYouTubeApi()
    .then(() => {
      const embeds = document.querySelectorAll(CLASSES.youtubeEmbed);
      embeds.forEach((embedComponent) => {
        new YouTubeEmbed(embedComponent);
      });
    })
};

const onDocumentReady = () => {
  if (!checkForYouTubeEmbed()) return;
  initYouTube();
};

if (document.readyState !== 'loading') {
  onDocumentReady();
} else {
  document.addEventListener("DOMContentLoaded", onDocumentReady);
}