// Stylesheets
import "./theme.scss";

// favicons
import "./resources/favicons/apple-touch-icon.png"
import "./resources/favicons/favicon-32x32.png"
import "./resources/favicons/favicon-16x16.png"
import "./resources/favicons/safari-pinned-tab.svg"

// Component Javascript
import "./components/experiencefragment/experiencefragment_header.js";
import "./components/socialmediasharing/socialMediaSharing.js";
import "./components/embed/embed_youtube.js";