import createSearchEvent from "../search/_search";

const toggleMobileMenu = () => {
  const mobileMenuElement = document.querySelector(".xf-header #mobile-menu");
  const textNavigationElement = document.querySelector('.xf-header .text-navigation-header');

  if (mobileMenuElement) {
    mobileMenuElement.addEventListener("click", function () {
      textNavigationElement.classList.toggle('is-open');
      mobileMenuElement.classList.toggle("cross");
    });
  }
}

const setActivePageClass = () => {
  const textNavigationElement = document.querySelector(".xf-header .text-navigation-header");

  if (textNavigationElement) {
    const links = textNavigationElement.querySelectorAll("a"); 

    links.forEach(link => {
      if (link.href === window.location.href) {
        link.classList.add("active");
      }
    });
  }
};

const onDocumentReady = () => {
  toggleMobileMenu();
  setActivePageClass();
  createSearchEvent();
}

if (document.readyState !== "loading") {
  onDocumentReady();
} else {
  document.addEventListener("DOMContentLoaded", onDocumentReady);
}

