const createSearchEvent = () => {
    const formWrapper = document.querySelector(".searchBox");

    formWrapper?.addEventListener("submit", (e) => {
        e.preventDefault();
        const formElement = formWrapper.querySelector("form");
        const searchInput = formWrapper.querySelector(".search-panel__input");
        const query = formElement.getAttribute("action");
        const inputVal = searchInput.value;
        const url = `${encodeURI(query)}?q=${encodeURIComponent(inputVal)}`;

        if (inputVal != "") {
            location.href = url;
        }
    });

    //Hide searchbar in header on /search
    const vueSearchPage = document.querySelector("#searchResult");

    if (vueSearchPage) {
        const dataParams = JSON.parse(vueSearchPage.dataset.params);
        const contentTypeParam = dataParams.contentTypeFilters[0].contentType;

        if (contentTypeParam === "Search") {
            formWrapper.classList.add("d-none");
        }
    }
};

export default createSearchEvent;
