const focusTrapClass = 'focus-trap';

export const setFocusTrap = (element) => {
    const firstFocusableEl = document.createElement('div');
    const lastFocusableEl = document.createElement('div');

    firstFocusableEl.tabIndex = 0;
    lastFocusableEl.tabIndex = 0;

    firstFocusableEl.classList.add(focusTrapClass);
    lastFocusableEl.classList.add(focusTrapClass);

    element.prepend(firstFocusableEl);
    element.append(lastFocusableEl);

    const focusTrap = (event) => {
        if (event.key === 'Tab') {
            if (event.shiftKey) {
                if (document.activeElement === firstFocusableEl) {
                    lastFocusableEl.focus();
                    event.preventDefault();
                }
            } else {
                if (document.activeElement === lastFocusableEl) {
                    firstFocusableEl.focus();
                    event.preventDefault();
                }
            }
        }
    };

    element.addEventListener('keydown', focusTrap);

    const destroy = () => {
        element.removeChild(firstFocusableEl);
        element.removeChild(lastFocusableEl);
        element.removeEventListener('keydown', focusTrap);
    };

    return { destroy };
};
