const getPageTitle = () => {
  return encodeURIComponent(document.title); 
}

const getCurrentLocation = () => {
  return window.location.href;
}

const getSocialLink = (iconStr) => {
  const currentUrl = getCurrentLocation();
  const currentPage = getPageTitle();

  const socialLinks = {
    linkedin: `https://www.linkedin.com/shareArticle?mini=true&url=${currentUrl}&title=${currentPage}`,
    "x-social": `https://twitter.com/share?text=${currentPage}&url=${currentUrl}&via=CCRRC`,
    facebook: `https://www.facebook.com/sharer/sharer.php?u=${currentUrl}`,
    googleshare: `https://plus.google.com/share?url=${currentUrl}`,
    wechat: `https://www.addtoany.com/add_to/wechat?linkurl=${currentUrl}&linkname=${currentPage}`,
    mail: `mailto:?subject=An%20Interesting%20Study%20for%20You%20via%20CCRRC&body=I%20read%20this%20study,%20${currentPage}%20and%20thought%20you%20might%20be%20interested%20in%20the%20topic:%20${currentUrl}`
  };
  return Object.hasOwn(socialLinks, iconStr) ? socialLinks[iconStr] : '';
}

const getSocialIdentifier = (aLink) => {
  const iconClassToMatch = 'cmp-button__icon--';
  const span = aLink.querySelector('span');
  if (!span) return '';
  const matchIconClass = Array.from(span.classList).filter((spanClass) => spanClass.startsWith(iconClassToMatch));
  if (!matchIconClass.length) return '';
  const classArr = matchIconClass[0].split('--');
  if (classArr.length < 2) return '';
  return classArr[1];
}

const replaceLink = (alink) => {
  const newLink = getSocialLink(getSocialIdentifier(alink));
  if (!alink) return;
  alink.setAttribute("href", newLink);
}

const replaceSocialButtonLinks = () => {
  const socialContainers = document.querySelectorAll('.container-social-buttons');
  if (!socialContainers) return;
  for (const socialContainer of socialContainers) {
    const buttonLinks = socialContainer.querySelectorAll('.cmp-button');
    for (const buttonLink of buttonLinks) {
      replaceLink(buttonLink);
    }
  }
}

if (document.readyState !== "loading") {
  replaceSocialButtonLinks();
} else {
  document.addEventListener("DOMContentLoaded", replaceSocialButtonLinks);
}
